@import '../../../../styles/customMediaQueries.css';

.root {
    width: 100%;
    padding: 24px;

    @media (--viewportLarge) {
        max-width: var(--contentMaxWidthPages);
        margin: 80px auto;
        padding: unset;
    }
}

.title {
    text-align: center;
    font-size: 28px;
    line-height: 34px;
    margin: 0;
    padding: 0;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media (--viewportLarge) {
        flex-direction: row;
        padding: 30px 60px 0 60px;
        gap: 40px;
        justify-content: space-between;
    }
}

.listing {
    width: 100%
}

.description {
    text-align: center;
    font-size: 18px;
    margin-bottom: 0;
}