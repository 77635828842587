@import '../../../../styles/customMediaQueries.css';

.root {
    position: relative;
    width: 100%;
    min-height: 70vh;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.root::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./img/LandingHero.png');
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
}

.container {
    width: calc(100% - 48px);
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: flex-end;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 8px;
    border: 2px solid rgba(255, 255, 255, 1);

    @media (--viewportLarge) {
        align-items: center;
        width: 648px;
    }
}

.title {
    font-family: Inter;
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    margin: 0;
    padding: 0;

    @media (--viewportLarge) {
        font-size: 36px;
        line-height: 44px;
    }
}

.ctaButton {
    width: 100%;
    text-align: center;
    color: var(--colorWhite);
    text-decoration: none;
    height: 48px;
    padding: 12px 16px;
    border-radius: 4px;
    background-color: var(--marketplaceColor);
    transition: var(--transitionStyleButton);

    &:hover {
        background-color: var(--marketplaceColorDark);
        text-decoration: none;
    }
}

.reviews {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;

    .excellent {
        color: var(--colorBlack);
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
    }
}

.icon {
    margin-left: 10px;
}

.info {
    text-align: center;
    font-size: 16px;
    margin-bottom: 0;
    justify-self: center;

    @media (--viewportLarge) {
        font-size: 18px;
        max-width: 50vw;
    }
}