@import '../../../../styles/customMediaQueries.css';

.root {
    background-color: var(--colorWhite);
    padding: 24px;

    @media (--viewportLarge) {
        margin: 80px auto;
        padding: 30px 0 0 0;
        max-width: var(--contentMaxWidthPages);
    }
}

.title {
    text-align: center;
    font-size: 28px;
    line-height: 34px;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;

    @media (--viewportLarge) {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 60px;
        justify-content: space-between;
        align-items: unset;
    }
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 310px;
}

.icon {
    width: 128px;
}

.cardTitle {
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    padding: 0;
    margin: 0;
}

.description {
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 18px;
}

.gold {
    color: var(--colorGold);
    font-weight: 700;
}