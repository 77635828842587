.root {
    display: flex;
    flex-direction: row;
    gap: 16px;
    background-color: #C5FFC266;
    padding: 64px;
    width: 100%;
    justify-content: center;

    .ctaButton {
        width: 276px;
        text-align: center;
        color: var(--colorWhite);
        text-decoration: none;
        height: 48px;
        padding: 12px 16px;
        border-radius: 4px;
        background-color: var(--marketplaceColor);
        transition: var(--transitionStyleButton);

        &:hover {
            background-color: var(--marketplaceColorDark);
        }
    }
}