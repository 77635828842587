@import '../../../../styles/customMediaQueries.css';

.root {
    width: 100%;
    background-color: var(--colorWhite);
    padding: 24px;

    @media (--viewportLarge) {
        margin: 80px auto 0 auto;
        padding: 30px 0 80px 0;
    }
}

.mainTitle {
    text-align: center;
    font-size: 28px;
    line-height: 34px;
}

.container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: space-between;

    a:hover {
        text-decoration: none;
    }

    @media (--viewportLarge) {
        flex-direction: row;
        padding: 30px 0 60px 0;
        max-width: var(--contentMaxWidthPages);

    }
}

.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
    width: 100%;

    img {
        width: 100%;

        &:hover {
            transform: scale(1.03);
            transition: var(--transitionStyleButton);
        }
    }

    @media (--viewportLarge) {
        width: 322px;
    }
}

.cardTitle {
    font-size: 22px;
    font-weight: 700;
}

.description {
    text-align: center;
    font-size: 18px;
    margin-bottom: 0;
    justify-self: center;

    @media (--viewportLarge) {
        max-width: 50vw;
    }
}