@import '../../../../styles/customMediaQueries.css';

.root {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
    padding: 24px;

    @media (--viewportLarge) {
        margin: 0 auto;
        max-width: var(--contentMaxWidthPages);
        gap: 40px;
        justify-content: space-between;
        padding: unset;
    }
}

.content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: center;
}

.title {
    text-align: center;
    font-size: 28px;
    line-height: 34px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    margin-bottom: 0;
    padding-bottom: 0;
}

.logo {
    width: 96px;

    @media (--viewportLarge) {
        width: 128px;
    }
}

.info {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
    padding: 0;
    text-align: center;

    @media (--viewportLarge) {
        width: 450px;
    }
}

.link {
    width: fit-content;
    text-align: center;
    color: var(--colorWhite);
    height: 48px;
    padding: 12px 16px;
    border-radius: 4px;
    background-color: var(--marketplaceColor);
    transition: var(--transitionStyleButton);

    &:hover {
        background-color: var(--marketplaceColorDark);
        text-decoration: none;
    }
}

.iframeContainer {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.iframeContainer iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}