@import '../../../../styles/customMediaQueries.css';

.root {
    width: 100%;
    background-color: var(--colorWhite);
    padding: 24px;

    @media (--viewportLarge) {
        margin: 0 auto;
        padding: 0 0 80px 0;
    }
}

.mainTitle {
    text-align: center;
    font-size: 28px;
    line-height: 34px;
}

.container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: space-between;

    @media (--viewportLarge) {
        flex-direction: row;
        padding: 60px 0;
        max-width: var(--contentMaxWidthPages);

    }
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    border-radius: 8px;
    background-color: var(--colorGrey50);

    @media (--viewportLarge) {
        width: 350px;
    }
}

.icon {
    height: 128px;
}

.cardTitle {
    font-size: 22px;
    font-weight: 700;
    text-align: center;

    @media (--viewportLarge) {
        min-height: 88px;
    }
}

.info {
    text-align: center;
    margin: 0;
    padding: 0;
    font-size: 16px;
}

.description {
    text-align: center;
    font-size: 18px;
    margin-bottom: 0;
    justify-self: center;

    @media (--viewportLarge) {
        max-width: 50vw;
    }
}