@import '../../../../styles/customMediaQueries.css';

.root {
    display: flex;
    flex-direction: row;
    padding: 64px 0;
    justify-content: space-between;
    max-width: var(--contentMaxWidthPages);
    margin: 0 auto;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
}

.title {
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
    margin: 0;
    padding: 0;
    width: 568px;
}

.info {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
    padding: 0;
    width: 568px;
}